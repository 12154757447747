<template>
  <main>
    <div class="layout-subcontainer">
      <h1 class="tit-h1">현장관리</h1>
      <div class="box-wrap">
        <div class="box-ct ">
          <div class="table-form">
            <table>
              <caption class="hide">
                사용자정보 항목
              </caption>
              <colgroup>
                <col width="148px" />
                <col width="" />
                <col width="148px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">건설사명</v-badge>
                  </th>
                  <td >
                    <div class="form-inp">
                      <v-select
                        :items="DROPITEMS_1"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        hide-details
                        @change="changeDetailBussType(V_CTCP_CD)"
                        v-model="V_CTCP_CD"
                        placeholder="선택하세요"
                      >
                      </v-select>
                    </div>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">사업지명</v-badge>
                  </th>
                  <td>
                    <div class="form-inp">
                      <v-select
                        :items="DROPITEMS_2"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        hide-details
                        @change="changeCampainList(V_BUSS_CODE)"
                        v-model="V_BUSS_CODE"
                        :disabled="DROPITEMS_2.length > 0 ? false : true"
                        placeholder="선택하세요"
                      >
                      </v-select>
                    </div>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">캠페인명</v-badge>
                  </th>
                  <td>
                    <div class="form-inp">
                      <v-select
                        :items="DROPITEMS_3"
                        item-text="CMP_NAME"
                        item-value="CMP_ID"
                        outlined
                        hide-details
                        v-model="V_CMP_ID"
                        @change="fnCmpChoice"
                        placeholder="선택하세요"
                      >
                      </v-select>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- 전체버튼 -->
      <div class="box-wrap">
        <div class="box-ct">

          <div class="col-12">
            <v-btn outlined large class="btn-default col-12" @click="fnOpenScreen('/CAP0510')">대기현황</v-btn>
          </div>
          <div class="col-12">
            <v-btn outlined large class="btn-default col-12" @click="fnOpenScreen('/CAP0520')">상담현황</v-btn>
          </div>

          <div class="col-12">
            <v-btn outlined large class="btn-etc2 col-12" @click="fnOpenScreen('/CAP0530')">현장방문확인</v-btn>
          </div>
          <div class="col-12">
            <v-btn outlined large class="btn-etc2 col-12" @click="fnOpenScreen('/CAP0540')">현장방문등록</v-btn>
          </div>
          <div class="col-12">
            <v-btn outlined large class="btn-etc col-12" @click="fnOpenScreen('/CAP0550')">상담예약</v-btn>
          </div>
          
          <div class="col-12">
            <v-btn outlined large class="btn-etc col-12" @click="fnOpenScreen('/CAP0560')">신규 테블릿 상담예약</v-btn>
          </div>
          <div class="col-12">
            <v-btn outlined large class="btn-etc col-12" @click="fnOpenScreen('/CAP0560M')">신규 모바일 상담예약</v-btn>
          </div>

          <div class="col-12" v-if="AUTH_CHK">
            <v-btn outlined large class="btn-etc col-12" @click="fnOpenScreen('/CAP0570')">신규 모바일 상담예약(설문버전)</v-btn>
          </div>
          <div class="col-12" v-if="AUTH_CHK">
            <v-btn outlined large class="btn-main-chatfunc col-12" @click="fnOpenScreen('/CAM0600')">사전예약</v-btn>
          </div>
          <div class="col-12" v-if="AUTH_CHK">
            <v-btn outlined large class="btn-main-chatfunc col-12" @click="fnOpenScreen('/CAP0700')">이벤트 설문</v-btn>
          </div>
        </div>

      </div>

    </div>

  </main>
</template>

<script>
  export default {
    name: "MENU_CAM0500", //name은 'MENU_' + 파일명 조합
    components: {
    },
    data() {
      return {
        DROPITEMS_1: [],
        DROPITEMS_2: [],
        DROPITEMS_3: [],
        allCodeList: [],
        
        GE_USER_ID: '',
        USER_AUTH_CD: '',
        V_CTCP_CD: '',
        V_BUSS_CODE: '',
        V_CMP_ID: '',
        V_CMP_NAME:'',

        AUTH_CHK: false,
      };
    },

    methods: {

      init() {
        let authChkIdArr = [];
        authChkIdArr.push('teletest');
        authChkIdArr.push('test');

        authChkIdArr.forEach((ele, i) => {
          if(ele == this.user_id) {
            this.AUTH_CHK = true;
          }
        });
      },

      fnCmpChoice(e) {
        this.DROPITEMS_3.forEach(element => {
          if(element.CMP_ID===this.V_CMP_ID){
            this.V_CMP_NAME=element.CMP_NAME;
          }
        });
      },

      fnOpenScreen(linkUrl) {
        if(this.V_CMP_ID == undefined || this.V_CMP_ID == '') {
          this.common_alert('캠페인명을 선택해주세요.', 'error');
          return;
        }

        if(linkUrl!=='/CAM0600'&&linkUrl!=='/CAP0700') {
          if(linkUrl.indexOf('CAM0620')<=-1){
            window.open(linkUrl+"?buss_code="+this.V_BUSS_CODE+"&cmp_id="+this.V_CMP_ID+"&cmp_name="+this.V_CMP_NAME, "_blank");
          }else{
            window.open(linkUrl+"?buss_code="+this.V_BUSS_CODE+"&cmp_id="+this.V_CMP_ID+"&phone_num=01011112222", "_blank");
          }
        }else{
          window.open(linkUrl+"?cmp_id="+this.V_CMP_ID+"&cmp_name="+this.V_CMP_NAME+"&buss_code="+this.V_BUSS_CODE+'&cust_phone=01000000000&mktg_agre_yn=Y&prsn_agre_yn=Y&plus_fred_key=test', "_blank");
        }
      },
      //사업지 코드 가져오기
      async changeDetailBussType(ctcpTypeCd) {
        this.DROPITEMS_2 = [];
        this.V_BUSS_CODE =  "";

        if (!this.mixin_isEmpty(ctcpTypeCd)) {
          if (this.USER_AUTH_CD == "SYSTEMMANAGER") {
            this.DROPITEMS_2 = await this.mixin_busscode_get(ctcpTypeCd);
          }else{
            this.DROPITEMS_2 = await this.mixin_busscode_get(ctcpTypeCd, this.GE_USER_ID);
          }
        }
      },
      
      //캠페인목록 가져오기
      async changeCampainList(bussCode){
        this.DROPITEMS_3 = [];

        if(bussCode != undefined && bussCode != ''){
          let requestData = {
            headers: {
              SERVICE: 'hlw.campaign.manage',
              METHOD: 'list',
              TYPE: 'BIZ_SERVICE',
            },
            sendData:{}
          };

          requestData.headers["URL"]        = "/api/hlw/campaign/manage/combo/list";
          requestData.sendData["BUSS_CODE"] = bussCode;

          let campaignList = await this.common_postCall(requestData, false);
          let resHeader = campaignList.HEADER;
          let resData   = campaignList.DATA;

          if (resHeader.ERROR_FLAG) {
            this.common_alert(this.alertMsg.error, "error");
            return false;
          }

          this.DROPITEMS_3 = resData;
        }
      },
    },

    async mounted() {
      this.init();
      
      let userGroupCd = this.user_role.userGroupCd;
      this.GE_USER_ID = this.user_id;
      const pArr = ['HLW_CTCP_CD'];
      
      this.allCodeList =  await this.mixin_common_code_get_all(pArr);

      if (userGroupCd == "SYSTEMMANAGER"){
        this.USER_AUTH_CD = userGroupCd;
        this.DROPITEMS_1 = await this.mixin_common_code_get(this.allCodeList,'HLW_CTCP_CD','');
      
      }else{
        let usserAuth = await this.mixin_buss_ctcp_code_user_auto(this.GE_USER_ID);

        usserAuth.forEach((element, i) => {
          this.DROPITEMS_1.push({ CD_NM: element.CTCP_NAME, CD: element.CTCP_CODE });
        });

        this.changeCampainList(this.V_BUSS_CODE);
      }
    },

    computed: {
      initHeaders(){
        return {
        };
      },

      paramData(){
        return {
        };
      }
    },
  };
</script>